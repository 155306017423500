// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './protator.webp';
import TermsOfService from './tos';
import PrivacyPolicy from './pp';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-between md:p-8">
        <Routes>
        <Route exact path="/" element={
            <div className="max-w-6xl w-full bg-gray-800 lg:rounded-3xl shadow-2xl p-12 lg:p-40">
              <img src={logo} alt="Protator Logo" className="w-40 h-40 lg:w-60 lg:h-60 rounded-full mx-auto transition duration-300 lg:hover:scale-110" />
              <div className="text-center mt-6 lg:mt-12">
                <h1 className="text-5xl lg:text-7xl font-bold text-transparent bg-clip-text gradient-animated">Protator</h1>
                <p className="mt-4 text-gray-300 text-lg lg:text-2xl transition duration-300 hover:text-gray-200">Safe and reliable high-speed captcha bot, effectively protect your server from bot raids and provide your members with a safe environment.</p>
              </div>
              <div className="mt-8 lg:mt-12 flex flex-col sm:flex-row justify-center gap-4 lg:gap-8">
                <a href="https://discord.gg/qQZmcDjnf4" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 lg:py-4 px-6 lg:px-8 rounded-full shadow-lg text-center">Join Support Server</a>
                <a href="https://discord.com/api/oauth2/authorize?client_id=1197074631098630204&permissions=1099780114434&scope=applications.commands%20bot" className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 lg:py-4 px-6 lg:px-8 rounded-full shadow-lg text-center">Invite Bot</a>
              </div>
              <div className="mt-10 lg:mt-16">
                <h2 className="text-3xl lg:text-4xl font-semibold text-center text-gray-100">Features</h2>
                <ul className="list-disc list-inside mt-4 lg:mt-8 lg:text-lg text-gray-400 space-y-2 lg:space-y-4">
                  <li className="flex items-center transition duration-300 lg:hover:text-gray-200">
                    <span className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 bg-blue-500 rounded-full transition duration-300 lg:hover:bg-blue-400"></span>
                    Ultimate security with unique captcha system
                  </li>
                  <li className="flex items-center transition duration-300 lg:hover:text-gray-200">
                    <span className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 bg-green-500 rounded-full transition duration-300 lg:hover:bg-green-400"></span>
                    Audio captchas now available!
                  </li>
                  <li className="flex items-center transition duration-300 lg:hover:text-gray-200">
                    <span className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 bg-yellow-500 rounded-full transition duration-300 lg:hover:bg-yellow-400"></span>
                    Top captcha generation time of less than 120ms
                  </li>
                  <li className="flex items-center transition duration-300 lg:hover:text-gray-200">
                    <span className="flex-shrink-0 w-4 h-4 lg:w-6 lg:h-6 mr-2 lg:mr-3 bg-red-500 rounded-full transition duration-300 lg:hover:bg-red-400"></span>
                    Set up in just 2 minutes using 
                    <code className="text-sm lg:text-lg bg-gray-700 px-2 py-1 rounded-md font-mono ml-2 transition-colors duration-300 hover:bg-gray-600 hover:text-red-300">/help</code>
                  </li>
                </ul>
              </div>
            </div>
             } />
             <Route path="/tos" element={<TermsOfService />} />
             <Route path="/pp" element={<PrivacyPolicy />} />
        </Routes>
        <footer className="w-full max-w-6xl text-center py-4 mt-8 lg:mt-12">
          <p className="text-sm lg:text-base text-gray-400">
            &copy; {new Date().getFullYear()} <a href="https://icelavaman.com" className="text-blue-400 hover:text-blue-300 transition duration-300">IceLavaMan</a>
          </p>
          <div className="mt-2 flex justify-center space-x-4 lg:space-x-6">
            <Link to="/tos" className="text-sm lg:text-base text-gray-400 hover:text-gray-300 transition duration-300">Terms of Service</Link>
            <Link to="/pp" className="text-sm lg:text-base text-gray-400 hover:text-gray-300 transition duration-300">Privacy Policy</Link>          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
