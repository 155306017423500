// TermsOfService.js
import React from 'react';
import { Link } from 'react-router-dom';

function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-start md:p-8">
      <div className="max-w-6xl w-full bg-gray-800 lg:rounded-3xl shadow-2xl p-12 lg:p-24">
        <h1 className="text-4xl lg:text-6xl font-bold text-center mb-8 lg:mb-12">Terms of Service</h1>
        <p className="text-lg lg:text-xl mb-6">
          Please read these Terms of Service ("Terms", "Terms of Service", "TOS") carefully before using the application Protator and this website, https://protator.xyz/*, both described as Service, operated by <a href="https://icelavaman.com" className="text-blue-400 hover:text-blue-300 transition duration-300">IceLavaMan</a> ("us", "we", or "our").
        </p>
        <p className="text-lg lg:text-xl mb-6">
          Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
        </p>
        <p className="text-lg lg:text-xl mb-6">
          By accessing or using the Service you agree to be bound by these Terms. We reserve the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">The Service</h2>
        <p className="text-lg lg:text-xl mb-6">
          Protator provides a Discord application. Protator is available via the invitation link located at <a href="https://protator.xyz" className="text-blue-400 hover:text-blue-300 transition duration-300">https://protator.xyz</a>.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">CAPTCHA System</h2>
        <p className="text-lg lg:text-xl mb-6">
          Protator is a CAPTCHA bot that generates images containing a code. Users are required to recognize the code and click the button with the correct code to verify their identity.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Prohibited Activities</h2>
        <p className="text-lg lg:text-xl mb-6">
          It is strictly prohibited to create automated programs or use any other means to solve the CAPTCHAs generated by Protator. Any attempt to circumvent or automate the CAPTCHA verification process is a violation of these Terms of Service.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Modifications to the Terms</h2>
        <p className="text-lg lg:text-xl mb-6">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        </p>
        <p className="text-lg lg:text-xl mb-6">
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
        </p>
        <p className="text-lg lg:text-xl">
          Last updated: March 21nd, 2024
        </p>
      </div>
      <footer className="w-full max-w-6xl text-center py-4 mt-8 lg:mt-12">
        <Link to="/" className="text-blue-400 hover:text-blue-300 transition duration-300">
          Back to Protator
        </Link>
      </footer>
    </div>
  );
}

export default TermsOfService;
