// PrivacyPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-start md:p-8">
      <div className="max-w-6xl w-full bg-gray-800 lg:rounded-3xl shadow-2xl p-12 lg:p-24">
        <h1 className="text-4xl lg:text-6xl font-bold text-center mb-8 lg:mb-12">Privacy Policy</h1>
        <p className="text-lg lg:text-xl mb-6">
          At Protator, accessible via Discord, one of our main priorities is the privacy of our users. This Privacy Policy document contains types of information that is collected and recorded by Protator and how we use it.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Data Collected from Server Owners</h2>
        <p className="text-lg lg:text-xl mb-6">
          Protator collects the following data from server owners who use our Discord bot:
        </p>
        <ul className="list-disc ml-6 mb-6 text-lg lg:text-xl">
          <li>Server ID</li>
          <li>User ID</li>
          <li>Role IDs</li>
          <li>Channel IDs</li>
        </ul>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Data Collected from Server Members</h2>
        <p className="text-lg lg:text-xl mb-6">
          Protator does not collect any data from server members, unless a verification attempt fails. In such cases, we collect the following data:
        </p>
        <ul className="list-disc ml-6 mb-6 text-lg lg:text-xl">
          <li>User ID</li>
          <li>Server ID of the server where the verification attempt occurred</li>
        </ul>
        <p className="text-lg lg:text-xl mb-6">
          If the AI Moderation module is enabled by the server administrators, the content of the messages can also be seen by the bot. However, it is important to note that no user-related or identifiable data is stored or used by Protator in any way.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Deleting Your Data</h2>
        <p className="text-lg lg:text-xl mb-6">
          If you wish to delete your data, you can do so by joining our Support Server <a href="https://discord.gg/qQZmcDjnf4" className="text-blue-400 hover:text-blue-300 transition duration-300">via this link</a> and contacting our support team.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Scope of this Privacy Policy</h2>
        <p className="text-lg lg:text-xl mb-6">
          This Privacy Policy applies only to the Protator service provided through Discord. On our website, we only use technically necessary cookies.
        </p>
        <h2 className="text-3xl lg:text-4xl font-semibold mb-4">Consent</h2>
        <p className="text-lg lg:text-xl">
          By using our service, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
      </div>
      <footer className="w-full max-w-6xl text-center py-4 mt-8 lg:mt-12">
        <Link to="/" className="text-blue-400 hover:text-blue-300 transition duration-300">
          Back to Protator homepage
        </Link>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
